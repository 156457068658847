import Vue from 'vue';
import {mapMutations} from 'vuex';
import {get, post, postBuffer} from '@/services/api';
import {downloadExcel, getAllVehiclesFiles, getExcelFile, uploadExcelFile} from '../../../services/endpoint.json';
import {saveAs} from 'file-saver';

export default Vue.extend({
  name: 'vehicles-file-management',
  data: () => ({
    columns: [
      {label:'Icon', key:'icon', sortable: true},
      {label:'Name', key:'name', sortable: true},
      {label:'Url', key:'url', sortable: true},
      {label:'Actions', key:'actions', sortable: true},
    ],
    tableData: [],
    file: '',
  }),
  created() {
    this.getDataExcelFileFromDataBase();
  },

  methods: {
    ...mapMutations(['loaderManager', 'notificationManager']),

    resetInputFile(e) {
      e.target.value = '';
    },

    onSelect(e) {
      this.file = e.target.files.item(0);
    },

    onSubmit() {
      this.loaderManager(true);
      const formData = new FormData();
      formData.append('file', this.file);
      post(uploadExcelFile, formData)
        .then((response) => {
          this.validateResponseUploadExcelFile(response.data);
        })
        .catch((error) => {
          this.loaderManager(false);
          this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title'), this.$i18n.t('upload_excel_file.notification_error_text'));
        });
    },

    async validateResponseUploadExcelFile(dataResponse) {
      if (dataResponse.statusCode === 200) {
        this.file = '';
        this.loaderManager(false);
        this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title'), this.$i18n.t('upload_excel_file.notification_success_text'));
        await this.getDataExcelFileFromDataBase();
      } else {
        this.loaderManager(false);
        this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title'), this.$i18n.t('upload_excel_file.notification_error_text'));
      }
    },

    async getDataExcelFileFromDataBase() {
      try {
        this.loaderManager(true);
        const {data} = await get(getAllVehiclesFiles, null, true);
        this.loaderManager(false);
        this.validateResponseGetDataExcelFileFromDataBase(data);
      } catch (e) {
        this.loaderManager(false);
      }
    },


    validateResponseGetDataExcelFileFromDataBase(dataResponse) {
      if (Number(dataResponse.statusCode) === Number(200)) {
        this.tableData = (Object.entries(dataResponse.message).length === 0) ? [] : dataResponse.message.reverse();
        this.loaderManager(false);
      } else {
        this.loaderManager(false);
        this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title_get_data'), this.$i18n.t('upload_excel_file.notification_error_text_get_data'));
      }
    },

    managerComponentNotification(title, text) {
      this.notificationManager({
        status: true,
        title,
        text,
      });
    },

    async exportExcelDB() {
      this.loaderManager(true);
      const {data} = await get(getExcelFile, this.fields, true);
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);

      let blob1 = new Blob([byteArray], {type: 'application/octet-stream'});
      const fileName = 'database.xlsx';
      saveAs(blob1, fileName);
      if (Number(data.statusCode) === Number(200)) {
        await this.getDataExcelFileFromDataBase();
        await this.showDownload(data.message, 'success');
      }
      this.loaderManager(false);
    },

    async showDownload(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: 'Your download is ready!',
        icon: (type === 'success') ? 'success' : 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    download(row) {
      postBuffer(downloadExcel, {name: row.name}).then(({data}) => {
        const fileName = `${row.name}`;
        const file2 = new File([data], fileName, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(file2);
      });
    }
  },

});
